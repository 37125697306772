import "./Experience.css";

const EXPERIENCE = [
  {
    position: "Software Engineer",
    company: "Skytek Ltd.",
    time: "May 2022 - Dec 2024",
    details: [
      "Technical leadership of projects",
      "Mentoring less experienced colleagues",
      "Work in fully remote environment",
      "Multinational (Irish, Polish, German, Romanian) team",
      "Meeting clients and discussing integration with their systems",
      "Meeting data providers and discussing integration with their systems",
    ],
    projects: [
      {
        name: "Real World / Situation Room",
        url: "https://skytek.com/react-insurance/",
        time: "May 2022 - Dec 2024",
        description:
          "Marine asset and risk management platform for insurance industry.",
        challenges: [
          "Processing of positions of hundreds of thousands of vessels worldwide",
          "Processing of positions of tens of thousands of aircraft worldwide",
          "Processing and combining  marine information from various sources",
          "Presentation of tens of thousands of assets on the map",
          "Created DRF-based backend framework to expose GIS data for the map",
          "Built Leaflet, React, Redux and Saga - based map capable of rendering custom tile layers and both vector and raster information using canvas",
          "Risk factor calculation based on proprietary rules",
          "Vessel voyage analysis",
          "Sanction compliance screening",
          "Environmental impact analysis",
          "Presentation of hurricane damage analysis on the map",
          "Solely responsible for defining AWS cloud infrastructure in Terraform, Dockerizing application and building the CI/CD pipeline",
          "BitBucket pipelines-based CI/CD",
        ],
        stack: [
          "Django",
          "DRF",
          "Kafka",
          "Faust",
          "Celery",
          "RabbitMQ",
          "PostgreSQL",
          "PostGIS",
          "React",
          "AWS ECS",
          "Terraform",
          "Docker",
          "Bitbucket Pipelines",
          "Github Actions",
        ],
      },
      {
        name: "Cyber Hub",
        url: "https://skytek.com/react-insurance/",
        time: "July 2023 - April 2024",
        description:
          "Cyber security risk assessment tool for insurance industry.",
        challenges: [
          "Running multiple open source and proprietary security analysis tools against various targets",
          "Utilizing TOR network to hide the origin of the traffic",
          "Complex dependency-oriented scheduling of security scans",
          "Terraformed infrastructure",
        ],
        stack: [
          "Django",
          "DRF",
          "Celery",
          "RabbitMQ",
          "PostgreSQL",
          "AWS ECS",
          "Terraform",
          "Docker",
          "Bitbucket Pipelines",
        ],
      },
    ],
  },
  // {
  //   position: "Software Engineer",
  //   company: "WSP Poland Sp. z o.o. / WSP Digital Australia",
  //   time: "January 2022 - May 2022",
  //   description: "My project has not started",
  // details: [
  //   "Worked on multiple small projects using AWS services",
  //   "Work in fully remote environment with Australian team",
  // ],
// },
  {
    position: "Senior Python Developer",
    company: "10Clouds Sp. z o.o.",
    time: "September 2017 - November 2021",
    description: "",
    details: [
      "Transitioned from JS Frontend Developer to Python Backend Developer in the first month",
      "Greenfield development of multiple Django-based projects",
      "Work in fully remote environment",
      "Multinational (Polish, US, Swedish, Italian) and cross-timezone team",
    ],
    projects: [
      {
        name: "EnrouteQ",
        url: "https://www.enrouteq.com",
        time: "September 2019 - November 2021",
        description:
          "Easy-to-use transit tools for creating and managing your data, schedules, and live vehicle locations.",
        challenges: [
          "Collection and processing of vehicle positions and driver activities",
          "Transit network management",
          "Bus voyage statistics",
          "Terraformed infrastructure",
        ],
        stack: [
          "Django",
          "DRF",
          "Celery",
          "RabbitMQ",
          "Kafka",
          "Faust",
          "PostgreSQL",
          "PostGIS",
          "AWS ECS",
          "Terraform",
          "Docker",
          "Jenkins",
        ],
      },
      // {
      //   name: "Zapp",
      //   description:
      //     "Electric scooter rental services",
      //   challenges: [
      //     "Two-way communication with scooters",
      //     "Solving performance bottlenecks",
      //   ],
      //   stack: [
      //     "Django",
      //     "DRF",
      //     "Postgres",
      //     "Celery",
      //     "RabbitMQ",
      //   ],
      // },
      {
        name: "Asmodee GEM (Global Event Manager) Portal",
        time: "January 2018 - May 2019",
        description: "Platform for gaming event organizers.",
        stack: ["Django", "DRF", "PostgreSQL", "Celery", "RabbitMQ", "Docker"],
      },
      {
        name: "Warsaw Museum e-Catalog",
        time: "September 2018",
        description: "On-line catalog of museum's exhibition items",
        challenges: [
          "Synchronization of catalog entries with museum's proprietary software",
          "Advanced search with Elasticsearch",
        ],
        stack: ["Django", "DRF", "PostgreSQL", "Elasticsearch", "Docker"],
      },
      {
        name: "Asmodee Product Catalog",
        time: "January 2018 - September 2019",
        description: "Product information synchronization utility.",
        challenges: [
          "Bi-directional synchronization of product catalog between studio and distribution websites",
          "Multi-language information processing",
        ],
        stack: [
          "Django",
          "Django CMS",
          "DRF",
          "PostgreSQL",
          "Elasticsearch",
          "Docker",
          "Robot Framework",
        ],
      },
      {
        name: "Desa CMS and auction item catalog",
        time: "September 2017 - February 2018",
        description:
          "CMS and auction catalog for polish biggest auction house. (tech stack: Django CMS, Elasticsearch, RabbitMQ, PostgreSQL, Docker)",
        challenges: [
          "Synchronization of catalog entries with client's proprietary software",
          "Advanced search with Elasticsearch",
          "Development of custom CMS components",
        ],
        stack: [
          "Django",
          "Django CMS",
          "DRF",
          "PostgreSQL",
          "Elasticsearch",
          "Docker",
        ],
      },
    ],
  },
  {
    position: "Full Stack Developer",
    company: "DAC Software Sp. z o.o.",
    time: "September 2016 - August 2017",
    details: [
      "Development of Qarson.fr platform",
      "Development of EDPAuto.fr platform",
      "Dockerization of the production and test environments",
      "Implementation of the monitoring of the production infrastructure",
      "Work related to development of internal sales-supporting tools",
      "Work in 5-person Scrum team",
    ],
  },
  {
    position: "Full Stack Developer, System Architect",
    company: "Flux Sp. z o.o.",
    time: "September 2010 - September 2016",
    details: [
      "Design and development of multiple web based applications",
      "Requirements analysis",
      "Project management",
      "UX development",
      "Infrastructure management",
      "Management of the development of Funnela CRM",
    ],
    projects: [
      {
        name: "Funnela CRM",
        time: "February 2006 - September 2017",
        description: "CRM-class system designed micro and small businesses.",
        stack: ["PHP", "Prado framework", "Vanilla JS", "PostgreSQL"],
      },
    ],
  },
  {
    position: "Full Stack Developer, System Architect",
    company: "True Solutions Sp. z o.o.",
    time: "December 2013 - September 2016",
    details: [
      "Design and development of multiple web based applications",
      "Requirements analysis",
      "Company and development team (up to 15 persons) management",
      "Development of multiple projects co-financed by EU",
    ],
    projects: [
      {
        name: "PlusKasa",
        time: "September 2012 - September 2016",
        customer: "PlusKasa S.A.",
        www: "http://www.pluskasa.pl/",
        description: "Micro-loan management and accounting system.",
        stack: ["PHP", "Prado framework", "Angular JS", "PostgreSQL"],
      },
      {
        name: "CodziennyPoznań.pl",
        time: "September 2015 - September 2017",
        customer: "Bozon Sp. z o.o., DMRW Sp. z o.o.",
        www: "http://www.codziennypoznan.pl/",
        description: "Custom CMS system for local news portal.",
        stack: ["PHP", "AngularJS", "Silex", "Propel", "PostgreSQL"],
      },
      {
        name: "EffectFactor",
        time: "May 2015 - November 2015",
        customer: "Llidero Sp. z o.o.",
        www: "https://www.effectfactor.net/",
        description: "Training efficiency monitoring system.",
        stack: ["PHP", "AngularJS", "Silex", "Propel", "PostgreSQL"],
      },
      {
        name: "FSA Project documentation archive",
        time: "September 2014 - September 2015",
        customer:
          "H. Cegielski-Fabryka Silników Agregatowych i Trakcyjnych Sp. z o.o.",
        description: "Archival project documentation management system.",
        stack: ["PHP", "AngularJS", "Silex", "Propel", "PostgreSQL"],
      },
    ],
  },
  {
    position: "Self-employed",
    company: "True Solutions Wiktor Latanowicz",
    time: "November 2008 - October 2010",
    details: ["Design and development of Funnela CRM"],
  },
  {
    position: "System Administrator, Network Administrator",
    company: "HARDS.PL",
    time: "2002 - 2006",
    details: [
      "Administration of multiple MS Active Directory servers",
      "Construction and configuration of computer networks",
      "Daily support for network users",
    ],
  },
];

const Project = (props) => {
  const { data } = props;
  const { name, time, stack, challenges } = data;
  return (
    <div className="row subsection print-no-break">
      <div className="row subsection">
        <div className="emph col project-name">
          Project: <b>{name}</b>
        </div>
        <div className="col-right light">{time}</div>
        <div className="col tech-stack">Tech stack: {stack.join(", ")}</div>
      </div>
      <div className="row subsection">
        <ul className="desc">
          {(challenges ?? []).map((det, i) => (
            <li key={i}>{det}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const Entry = (props) => {
  const { data } = props;
  const { position, company, time, projects, details } = data;

  return (
    <div className="section-text col-right">
      <div className="row">
        <div className="col">
          <h3>{company}</h3>
        </div>
        <div className="col-right light">{time}</div>
      </div>
      <div className="row subsection">
        <div className="emph col">{position}</div>
      </div>
      {details && <ul className="desc">
        {details.map((det, i) => (
          <li key={i}>{det}</li>
        ))}
      </ul>}
      {projects && (
        <div className="row subsection projects">
          {projects.map((e, i) => (
            <Project key={i} data={e} />
          ))}
        </div>
      )}
    </div>
  );
};

const Experience = () => {
  return (
    <div className="section row">
      <h2 className="col">Experience</h2>
      {EXPERIENCE.map((e, i) => (
        <Entry key={i} data={e} />
      ))}
    </div>
  );
};

export default Experience;
